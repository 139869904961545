import { MutationTree } from "vuex";
import { UserMutationTypes } from "@/store/modules/user/mutation-types";
import { State } from "@/store/modules/user/state";

export type Mutations<S = State> = {
  [UserMutationTypes.SET_TOKEN](state: S, payload: string): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [UserMutationTypes.SET_TOKEN](state, payload: string) {
    state.token = payload;
    state.isLoggedIn = true;
  },
};
