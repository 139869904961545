import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import Notifications from "@kyvg/vue3-notification";
import "@/scss/index.scss"; //Custom css classes

loadFonts();
const app = createApp(App);

app.use(router).use(store).use(vuetify).use(Notifications).mount("#app");
