import { IUserInfo } from "@/types/user"

export type State = {
  isLoggedIn: boolean
  token: string
  info: IUserInfo | null
}

export const state: State = {
  isLoggedIn: false,
  token: "",
  info: null,
}
