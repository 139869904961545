<template>
  <v-layout>
    <v-main>
      <router-view />
    </v-main>
  </v-layout>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "LoginLayout",
});
</script>

<style scoped></style>
