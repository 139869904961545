import request from "@/services/requestService";
import { ILoginCredentials } from "@/types/user";

export function login(data: ILoginCredentials) {
  return request({
    url: "api/login",
    method: "post",
    data,
  });
}
