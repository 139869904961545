import axios, { AxiosError } from "axios"

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: 120000, // request timeout
})

service.interceptors.request.use(
  (config) => {
    if (config.headers) {
      const token = localStorage.getItem("token")
      config.headers["Access-Control-Allow-Origin"] = "*"

      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`
      }
    }

    return config
  },
  (error: AxiosError) => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  (response) => {
    return response
  },
  (error: AxiosError) => {
    if (error?.response?.status === 403) {
      console.log("Redirect to login page")
    }
    return Promise.reject(error)
  }
)

export default service
