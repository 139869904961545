import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const loadComponent = (view: string) => {
  return () => import(`../views/${view}.vue`);
};

const routes: Array<RouteRecordRaw> = [
  {
    path: "/:catchAll(.*)",
    redirect: "/",
  },
  {
    path: "/",
    redirect: "/start",
    meta: {
      layout: "LoginLayout",
      //roles: [ROLE.COMPANY_ADMIN],
    },
    children:[
      {
        path: "start",
        name: "start",
        component: loadComponent("MentTestView/MentTestView"),
      },
      {
        path: "test1",
        name: "test1",
        component: loadComponent("MentTestView/MentTestView1"),
      },
      {
        path: "test2",
        name: "test2",
        component: loadComponent("MentTestView/MentTestView2"),
      },
      {
        path: "test3",
        name: "test3",
        component: loadComponent("MentTestView/MentTestView3"),
      },
    ]
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
