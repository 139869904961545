import { IUserInfo } from "@/types/user"
import { GetterTree } from "vuex"
import { State } from "@/store/modules/user/state"
import { RootState } from "@/store"

export type Getters = {
  userInfo(state: State): IUserInfo
}

export const getters: GetterTree<State, RootState> & Getters = {
  userInfo: (state) => state.info,
}
