
import { defineComponent } from "vue";
import { AppViews } from "@/components/AppViews";

export default defineComponent({
  name: "App",
  components: { AppViews },

  data() {
    return {
      appData:"",
      prtscr:false
    };
  },
  methods: {
    fullScreen() {
      this.toggleFullScreen();
    },
    stopPrintScreen(){
      if(!this.prtscr){
          this.prtscr = true;
          const box = document.getElementById('print-screen-data');
          box.style.visibility = "visible";
        }
    },
    removePrintScreenDataAndReturnOriginalContent(){
      if(this.prtscr){
        const box = document.getElementById('print-screen-data');
        box.style.visibility = "hidden";
        this.prtscr = false;
      }
    },
    toggleFullScreen() {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
      } else if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    },
  },

  mounted() {

    window.addEventListener("mousedown",(event)=>{
      console.log("MOUSE DOWN",event);

    });
    window.addEventListener("mouseup",(event)=>{
      console.log("MOUSE UP",event);
      this.removePrintScreenDataAndReturnOriginalContent()
    });

    window.addEventListener("contextmenu", (event) => {
        event.preventDefault();
    });

    window.addEventListener("keyup", (event) => {
      console.log("KEY UP",event.keyCode);
      if(event.keyCode == 44){
        this.stopPrintScreen()
      }
      event.preventDefault();
    });

    window.addEventListener("keydown", (event) => {
      console.log("KEY DOWN",event.keyCode);
      event.preventDefault();
      if(event.keyCode == 91){
        this.stopPrintScreen()
      }
      // if (event.keyCode == 123) {
      //   // Prevent F12

      // } else if (event.ctrlKey && event.shiftKey && event.keyCode == 73) {
      //   // Prevent Ctrl+Shift+I
      //   event.preventDefault();
      // } else if(event.keyCode == 44){
      //   event.preventDefault();
      // } else if(event.keyCode == 44 && event.){
      //   event.preventDefault();
      // }
    });
  },
});
