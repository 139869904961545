
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import { LoginLayout } from "@/layouts/LoginLayout";
import { MainLayout } from "@/layouts/MainLayout"; //TODO Lazy load this

export default defineComponent({
  name: "AppViews",
  components: {
    MainLayout,
    LoginLayout,
  },
  computed: {
    layout(): string {
      const { meta } = useRoute();
      const defaultLayout = "MainLayout";
      return meta.layout || defaultLayout;
    },
  },
});
