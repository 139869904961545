// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import "@/scss/variables.scss";

// Vuetify
import { createVuetify } from "vuetify";

// Components and directives
import * as components from "vuetify/lib/components";
import * as directives from "vuetify/lib/directives";

// Theme definition
const myCustomLightTheme = {
  dark: false,
  colors: {
    lilac: "#F5F5FC",
    primary: "#0A0F32",
    burgundy: "#B9325A",
    "light-gray": "#CFD8E0",
    gray: "#BBC7D2",
    "dark-gray": "#838492",
  },
};

export default createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: "myCustomLightTheme",
    themes: {
      myCustomLightTheme,
    },
  },
});
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
