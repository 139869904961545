
import { defineComponent } from "vue";


export default defineComponent({
  name: "MainLayout",
  components: {

  },
});
