import { ActionContext, ActionTree } from "vuex";
import { RootState } from "@/store";
import { UserActionTypes } from "@/store/modules/user/action-types";
import { login } from "@/api/user";
import { UserMutationTypes } from "@/store/modules/user/mutation-types";
import { clearToken, setToken } from "@/utils/auth";

import { ILoginCredentials } from "@/types/user";
import { Mutations } from "@/store/modules/user/mutations";
import { State } from "@/store/modules/user/state";

// Inappropriate mutations will be not allowed
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

// Define actions types
export interface Actions {
  [UserActionTypes.LOGIN](
    { commit }: AugmentedActionContext,
    payload: ILoginCredentials
  ): Promise<string>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [UserActionTypes.LOGIN]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      login(payload)
        .then((response) => {
          const { token } = response.data;
          commit(UserMutationTypes.SET_TOKEN, token);
          setToken(token);
          resolve(token);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [UserActionTypes.LOGOUT]({ commit }) {
    return new Promise((resolve) => {
      commit(UserMutationTypes.SET_TOKEN, "");
      clearToken();
      resolve(true);
    });
  },
};
